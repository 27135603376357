<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="emailsOrig"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            >
                            <template v-slot:[`item.to_email`]="{item}">
                                <ul>
                                    <li style="list-style-type:none" v-for="(i, index) in JSON.parse(item.to_email)" :key="index">{{i}}</li>
                                </ul>
                            </template>
                            <template v-slot:[`item.cc_email`]="{item}">
                                <ul>
                                    <li style="list-style-type:none" v-for="(i, index) in JSON.parse(item.cc_email)" :key="index">{{i}}</li>
                                </ul>
                            </template>
                            <template v-slot:[`item.bcc_email`]="{item}">
                                <ul>
                                    <li style="list-style-type:none" v-for="(i, index) in JSON.parse(item.bcc_email)" :key="index">{{i}}</li>
                                </ul>
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <v-btn
                                    text
                                    color="primary"
                                    @click="edit(item)"
                                    >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                v-model="editDialog"
                persistent
                max-width="600px"
                >
                <v-card>
                    <v-card-title>
                    <span class="text-h5">Edit {{getTitle()}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <!-- <v-col
                                    cols="12"
                                    >
                                    <v-text-field
                                        label="Title"
                                        v-model="selectedItem.title"
                                    ></v-text-field>
                                </v-col> -->

                                <v-col v-if="noSubject"
                                    cols="12"
                                    >
                                    <v-text-field
                                        label="Subject"
                                        v-model="selectedItem.subject"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    v-if="!noEmailTo"
                                    cols="12"
                                    >
                                    <span>Email To</span>
                                   <v-combobox
                                        v-model="selectedItem.to_email"
                                        :items="selectedItem.to_email"
                                        small-chips
                                        clearable
                                        :hide-selected="hideSelectedReturn()"
                                        append-icon=""
                                        multiple
                                        solo
                                        >
                                        <template v-slot:selection="{ attrs, item, selected }">
                                            <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click:close="removeTO(item)"
                                            >
                                            <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item)">
                                                <span class="white--text text-body-2">{{ avatarInitial(item) }}</span>
                                            </v-avatar>
                                            <strong>{{ item }}</strong>&nbsp;
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            
                            
                                <v-col
                                    cols="12"
                                    >
                                    <span>CC</span>
                                   <v-combobox
                                        v-model="selectedItem.cc_email"
                                        :items="selectedItem.cc_email"
                                        small-chips
                                        clearable
                                        :hide-selected="hideSelectedReturn()"
                                        append-icon=""
                                        multiple
                                        solo
                                        >
                                        <template v-slot:selection="{ attrs, item, selected }">
                                            <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click:close="removeCC(item)"
                                            >
                                            <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item)">
                                                <span class="white--text text-body-2">{{ avatarInitial(item) }}</span>
                                            </v-avatar>
                                            <strong>{{ item }}</strong>&nbsp;
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            
                                <v-col
                                    v-if="noBCC"
                                    cols="12"
                                    >
                                    <span>BCC</span>
                                   <v-combobox
                                        v-model="selectedItem.bcc_email"
                                        :items="selectedItem.bcc_email"
                                        small-chips
                                        clearable
                                        :hide-selected="hideSelectedReturn()"
                                        append-icon=""
                                        multiple
                                        solo
                                        >
                                        <template v-slot:selection="{ attrs, item, selected }">
                                            <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            close
                                            @click:close="removeBCC(item)"
                                            >
                                            <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item)">
                                                <span class="white--text text-body-2">{{ avatarInitial(item) }}</span>
                                            </v-avatar>
                                            <strong>{{ item }}</strong>&nbsp;
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="cancelEdit"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            v-if="selectedItem.subject || noSubject == false"
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Save
                        </v-btn>
                        <v-btn
                            v-else
                            disabled
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Users',
    data () {
        return {
            noSubject: true,
            noEmailTo: true,
            noBCC: true,
            origSelectedItem: '',
            selectedItem: '',
            editDialog: false,
            selectedUser: '',
            headers: [
                {
                    text: 'Title',
                    sortable: true,
                    value: 'title'
                },
                {
                    text: 'Subject',
                    sortable: true,
                    value: 'subject'
                },
                {
                    text: 'To',
                    sortable: false,
                    value: 'to_email'
                },
                {
                    text: 'CC',
                    sortable: false,
                    value: 'cc_email'
                },
                {
                    text: 'BCC',
                    sortable: false,
                    value: 'bcc_email'
                },
                {
                    text: 'Action',
                    sortable: false,
                    value: 'action'
                }
            ],
            emails: [],
            emailsOrig: []
        }
    },
    computed: {
        ...mapGetters({
        currUser: 'auth/currUser'
        })
    },
    async mounted () {
        const res = await this.$store.dispatch('email/doGetRecipients')
        this.emails = res.data.result
        this.emailsOrig = JSON.parse(JSON.stringify(res.data.result))
    },
    methods: {
        async save () {
            console.log(this.selectedItem)

            if (this.selectedItem.subject == null){
                console.log('NULL')
                console.log(typeof this.selectedItem.subject)
                console.log(this.selectedItem.subject)
                this.selectedItem.subject = 'Subject'
            }

            const payload = {id: this.selectedItem.id, payload: this.selectedItem}
            const res = await this.$store.dispatch('email/doUpdateRecipients', {payload})
            console.log(res)
            if(res.data.code === 200){
                console.log('hello')
                this.editDialog = false
                const resEmail = await this.$store.dispatch('email/doGetRecipients')
                this.emails = resEmail.data.result
                this.emailsOrig = JSON.parse(JSON.stringify(resEmail.data.result))
            }
        },  
        cancelEdit () {
            var index = this.emails.findIndex( e => e.id === this.selectedItem.id)
            var filtered = this.emailsOrig.filter( e => e.id === this.selectedItem.id)
            this.emails[index].title = filtered[0].title
            this.emails[index].subject = filtered[0].subject
            this.emails[index].bcc_email = filtered[0].bcc_email
            this.emails[index].cc_email = filtered[0].cc_email
            this.emails[index].to_email = filtered[0].to_email
            this.selectedItem = ''
            this.editDialog = false
        },
        hideSelectedReturn () {
            return true
        },
        removeCC (item) {
            var index = this.selectedItem.cc_email.indexOf(item)
            this.selectedItem.cc_email.splice(index, 1)
        },
        removeBCC (item) {
            var index = this.selectedItem.bcc_email.indexOf(item)
            this.selectedItem.bcc_email.splice(index, 1)
        },
        removeTO (item) {
            var index = this.selectedItem.to_email.indexOf(item)
            this.selectedItem.to_email.splice(index, 1)
        },
        getTitle () {
            if(this.selectedItem !== ''){
                 var filtered = this.emailsOrig.filter( e => e.id === this.selectedItem.id )
                return filtered[0].title
            }
        },
        edit (item) {
            // Remove Email To:
            if(item.title == 'Expired Documents' || item.title == 'Returned by DET Compliance Approver' || item.title == 'Returned By Validation Committee'|| item.title == 'Returned By Onboarding Committee' || item.title == 'Returned By Evaluation Committee' || item.title == 'Disapproved By President' || item.title == 'Finance'){
                this.noEmailTo = true 
            } else {
                this.noEmailTo = false
            }
            // Remove Subject
            if(item.title == 'Finance'){
                this.noSubject = false 
            } else {
                this.noSubject = true
            }
            // Remove BCC
            if(item.title == 'Finance'){
                this.noBCC = false 
            } else {
                this.noBCC = true
            }

            
            var filtered = this.emails.filter( e => e.id === item.id)
            this.selectedItem = filtered[0]
            console.log(filtered[0])
            this.selectedItem.cc_email = Array.isArray(filtered[0].cc_email) ?   filtered[0].cc_email : JSON.parse(filtered[0].cc_email)
            this.selectedItem.bcc_email = Array.isArray(filtered[0].bcc_email) ?  filtered[0].bcc_email : JSON.parse(filtered[0].bcc_email)
            this.selectedItem.to_email = Array.isArray(filtered[0].to_email)? filtered[0].to_email : JSON.parse(filtered[0].to_email) 
            this.editDialog = true
        },
        avatarColor (fullname, ligthness = 40, saturation = 75) {
            let hash = 0

            if (fullname) {
                for (let i = 0; i < fullname.length; i++) {
                hash = fullname.charCodeAt(i) + ((hash << 5) - hash)
                }
                const hue = hash % 360
                return `background-color: hsl(${hue}, ${saturation}%, ${ligthness}%) !important`
            }
            return ''
        },
        avatarInitial (fullname)  {
            let initial = ''

            if (fullname) {
                let name = ''

                fullname = fullname.toUpperCase()

                if (fullname.indexOf(',') !== -1) {
                fullname = fullname.split(',')
                name = fullname[1]?.replace(/(^\s+|\s+$)/g, '')
                } else {
                name = fullname
                }

                initial = name.substr(0, 1)
            }
            return initial
        }
    }
}
</script>
<style scoped>
    .v-data-table.ph-nonex-datatable {
    /* background: #FFFFFF; */
    box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
    }

    .ph-nonex-sender-container,
    .ph-nonex-receiver-container {
    display: flex;
    }

    .ph-nonex-table-avatar {
    margin: auto 0;
    }

    .ph-nonex-table-names-text,
    .ph-nonex-status-text {
    margin: auto;
    margin-left: 10px;
    }

    .ph-nonex-status-circle {
    height: 10px;
    width: 10px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: auto 0;
    }

    .ph-nonex-status-success {
    background: #46B746;
    }

    .ph-nonex-status-success-text {
    color: #46B746;
    }

    .ph-nonex-status-pending {
    background: #F76F34;
    }

    .ph-nonex-status-pending-text {
    color: #F76F34;
    }

    .ph-nonex-status-container {
    display: flex;
    }

    .ph-nonex-action-container {
    display: flex;
    flex-direction: row;
    }

    .ph-wu-logo {
    height: 25px;
    width: 10px;
    }

    .ph-nonex-logo {
    transform: scale(0.45);
    border-radius: 0;
    }

    .ph-fulfill-logo {
    transform: scale(0.7);
    border-radius: 0;
    }

    .ph-tooltip {
    background: #EDEDED;
    color: #000;
    border-radius: 50px;
    }

    .ph-nonex-table-names-text.text-amount {
    margin-left: 0 !important;
    }

    .text-inquire-button {
    cursor: pointer;
    }
</style>
